import { Dancing_Script } from "@next/font/google";
const dancingScript = Dancing_Script();

export const Subtitle: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <h2 className={["text-5xl", dancingScript.className].join(" ")}>
      {children}
    </h2>
  );
};
