import { motion, Variants } from "framer-motion";
import { Container } from "./Container";

export const AnimatedContainer: React.FC<{
  animations: Variants;
  containerClasses?: string;
  children: React.ReactNode;
}> = ({ animations, containerClasses, children }) => {
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 1 }}
    >
      <Container classes={containerClasses}>{children}</Container>
    </motion.div>
  );
};
