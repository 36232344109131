export const Container: React.FC<{ children: React.ReactNode; classes?: string; }> = ({
  classes,
  children,
}) => {
  const innerClasses = `py-12 flex flex-col space-y items-center justify-center ${classes}`
  return (
    <div className={innerClasses}>
      {children}
    </div>
  );
};
