import Head from "next/head";
import Image from "next/image";
import { Button } from "../components/Button";
import { AnimatedContainer, Content, Subtitle, Title } from "../components/";
import { useRouter } from "next/router";
import clientApi from "services/api";
import { v4 as uuidv4 } from "uuid";
import storageApi from 'services/client-storage';
import { useState } from "react";
import { getCountry } from "services/geolocation-service";

export default function Home() {
  const [ loading, setLoading ] = useState(false);
  const router = useRouter();
  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const handleClick = async () => {
    setLoading(true);
    const newUuid = await uuidv4();
    const oldSession = await storageApi.getPhotoSessionId();
    await clientApi.upsertPhotoSession({
      id: newUuid,
      sessionReferralId: oldSession ?? undefined,
      country: getCountry() ?? undefined,
    }).then((data) => {
      if (data.type === "success") {
        storageApi.setPhotoSessionId(newUuid);
        router.push("register-form");
      } else {
        // manage the error if necessary
      }
    }).finally(() => setLoading(false));
  };

  return (
    <>
      <Head>
        <title>Dear Nipple</title>
        <meta name="description" content="Dear Nipple welcome page." />
      </Head>
      <AnimatedContainer animations={animations}>
        <Image
          src="/dear-nipple-logo.png"
          alt="jewerly nipple silver"
          className="w-[120px] object-cover mb-12 mt-6 h-32"
          width={202}
          height={228}
          priority
        />
        <Content>
          <p className="text-center">
            Dear Nipple
          </p>
          <p className="text-center mb-6">
            is an invitation to reconnect with our original abundance, pleasure and love. 
          </p>
          <p className="text-center mb-6">
            It&apos;s a way to remember the loving bond that brought us into this life, and
            a channel to discover the ancestral wisdom of our body.
          </p>
          <p className="text-center">
            Dear Nipple is a way of sharing us from love.
          </p>
          <p className="text-center mb-12">
            (  .  )
          </p>
          {/* <p>
            Through this app it will be possible to scan the nipple in a
            completely easy and safe way, which will be sent to Bali
            where artisans will transform it into a totally unique silver
            and or gold plated pendant. When it is ready for delivery
            you will receive an email confirming your shipping address.
          </p> */}
          <div className="flex justify-center"> 
            <Button onClick={handleClick} loading={loading}> Let&apos;s get started </Button>
          </div>
        </Content>
      </AnimatedContainer>
    </>
  );
}
